import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

type State = {
  uploadProgress: Record<string, number>
}

const initialState: State = {
  uploadProgress: {},
}

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setProgress: (
      state,
      action: PayloadAction<{ name: string; progress: number }>,
    ) => {
      state.uploadProgress = {
        ...state.uploadProgress,
        [action.payload.name]: action.payload.progress,
      }
    },
    resetProgress: (state) => {
      state.uploadProgress = {}
    },
  },
})

export const UploadFileRequest =
  (data: File, id: string, url: string) =>
  (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve({
            file: URL.createObjectURL(data),
            id,
            type: data.type,
            name: data.name,
          })
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
            id,
            type: data.type,
            name: data.name,
          })
        }
      }
      xhr.onerror = () => {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
          id,
          type: data.type,
          name: data.name,
        })
      }
      xhr.upload.addEventListener(
        "progress",
        ({ loaded, total }: ProgressEvent<XMLHttpRequestEventTarget>) => {
          const progress = parseInt(((loaded / total) * 100).toFixed())
          dispatch(setProgress({ name: data.name, progress }))
        },
        false,
      )
      xhr.open("PUT", `${url}`)
      xhr.setRequestHeader("Cache-Control", "max-age=864000, public")
      xhr.setRequestHeader("Content-Type", `${data.type}`)
      xhr.send(data)
    })
  }

export const { setProgress, resetProgress } = uploadSlice.actions
export default uploadSlice.reducer

export const selectProgress = (state: RootState) => state.upload.uploadProgress
