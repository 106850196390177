import React from "react"
import {
  Button,
  Grid,
  Typography,
  Modal,
  CircularProgress,
  Box,
} from "@mui/material"

import { WarningAmberOutlined } from "@mui/icons-material"

const styles = () => ({
  container: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  },
  btnCancel: {
    minWidth: "90px",
    backgroundColor: "#fff",
    color: "#000",
    border: "0.2px solid #000",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#0D99FF",
      border: "1px solid #0D99FF",
    },
  },
  btnSubmit: {
    minWidth: "90px",
    backgroundColor: "#0D99FF",
    color: "#fff",
    border: "1px solid #0D99FF",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#0D99FF",
    },
  },
})

type ConfirmModalProps = {
  handleClose: () => void
  handleSubmit: () => void
  confirmText: string
  open: boolean
  isLoading?: boolean
  warningText?: string
}

export const ConfirmModal = ({
  handleClose,
  handleSubmit,
  confirmText,
  open,
  isLoading,
  warningText,
}: ConfirmModalProps) => {
  const customStyles = styles()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container direction="column" gap={3} sx={customStyles.container}>
        {warningText ? (
          <Box
            display="flex"
            alignItems="center"
            border={1}
            borderColor="grey.400"
            p={1}
            borderRadius="8px"
          >
            <WarningAmberOutlined
              style={{
                fontSize: 30,
                marginRight: 8,
                strokeWidth: "0.2",
                color: "red",
              }}
            />
            <Typography variant="button">
              <span style={{ fontWeight: "bold", color: "red" }}>Warning:</span>{" "}
              {warningText}
            </Typography>
          </Box>
        ) : null}
        <Typography variant="h6">{confirmText}</Typography>
        <Grid item container gap={3} justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleClose}
            sx={customStyles.btnCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={customStyles.btnSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "#000" }} />
            ) : (
              "Confirm"
            )}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}
