import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import * as Sentry from "@sentry/react"

import { store, storePersistor } from "./app/store.js"

import { AppTour } from "./components/AppTour.js"

import "@fontsource/roboto"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "./index.css"

// Determine if Sentry should be enabled
// Sentry is enabled for all environments except development or if the environment is not set
const SentryEnabled =
  import.meta.env.VITE_KWALEE_ENV &&
  import.meta.env.VITE_KWALEE_ENV !== "development"
    ? true
    : false

Sentry.init({
  dsn: "https://81607b82b2af8afc5607942fdc6ed7f3@o4506824532819968.ingest.us.sentry.io/4507702844129280",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://www.staging-creative-manager.kwalee.com",
    "https://www.creative-manager.kwalee.com",
  ],
  environment: import.meta.env.VITE_KWALEE_ENV,
  enabled: SentryEnabled,
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={storePersistor}>
        <BrowserRouter>
          <AppTour />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
