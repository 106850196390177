import { TagObjectType } from "../model/creative"

export const merge = (
  a: TagObjectType[],
  b: TagObjectType[],
  predicate = (a: TagObjectType, b: TagObjectType) => a === b,
) => {
  const c = [...a] // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) =>
    c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem),
  )
  return c
}

export const getCompulsoryTags = (data: TagObjectType[] | undefined) => {
  let compulsoryArr = [] as TagObjectType[]
  if (!!data) {
    data.forEach((tag) => {
      if (!!tag.compulsory) {
        if (!compulsoryArr.some((x) => x.tagName === tag.tagName))
          compulsoryArr.push(tag)
      }
    })
  }
  return {
    compulsoryArr,
    valTags: [...compulsoryArr],
  }
}

export const getTagsValues = (
  arr: TagObjectType[],
  tagData: TagObjectType[],
  data?: Record<string, string>,
) => {
  let dependentsArr = [] as TagObjectType[]
  let nonDependentArr = [] as TagObjectType[]
  let valArr = [] as TagObjectType[]

  if (!!tagData) {
    arr.forEach((val) => {
      // Get all dependents
      if (val.compulsory) {
      } else {
      }
    })

    if (!!dependentsArr) {
      // Add all tags from array and dependents array and remove duplicates
      valArr = merge(arr, [...dependentsArr], (a, b) => a.tagName === b.tagName)
      // Get all non dependents tags
      nonDependentArr = arr.filter(
        (val) => !dependentsArr.some((d) => d.tagName === val.tagName),
      )
    } else {
      dependentsArr = []
      nonDependentArr = arr
      valArr = arr
    }
  }
  return {
    tags: nonDependentArr,
    dependentTags: dependentsArr,
    valTags: valArr,
    finalTags: {
      ...valArr.reduce(
        (a, v) => ({
          ...a,
          [v.tagName]: !!data ? data[v.tagName] || "" : "",
        }),
        {},
      ),
    },
  }
}
