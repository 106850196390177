import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material"
import { EditOutlined, KeyboardBackspace } from "@mui/icons-material"
import { Form, Formik } from "formik"

import {
  AiDataTypes,
  CreativeAiMetadataTypes,
  CreativeObjectType,
  CreativeTagTypes,
  TagObjectType,
} from "@/model/creative.js"

import { TagFieldComponent, TagsSelectorComponent } from "./TagViewComponent.js"

import {
  useEditTagsInAssetMutation,
  useLazyGetTagsByAssetCategoryQuery,
} from "@/app/services/tags.js"
import { useAppSelector } from "@/app/hooks.js"
import { enqueueSnackbar } from "@/features/notifier/notifierSlice.js"
import { selectReadOnlyUser } from "@/features/auth/authSlice.js"

import { createCustomName, downloadS3AsBlob } from "@/util/downloadFromS3.js"
import { getCompulsoryTags } from "@/util/getTags.js"
import { getUpdatedAiTags } from "@/util/getUpdatedAiTags.js"

import downloadIcon from "@/assets/download-icon-bordered.png"

type EditTagsModalProps = {
  open: boolean
  handleClose: () => void
  anchor?: HTMLElement | null
  data: CreativeObjectType
  url: string | undefined
  tagData?: TagObjectType[]
  isEditMode?: Boolean
  assetCategory:
    | {
        display_name: string
        category_id: string
      }
    | undefined
  assetCategoryData: {
    display_name: string
    category_id: string
  }[]
}

const styles = () => ({
  container: {
    borderRadius: "10px",
    background: "#FFF",
    boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.05)",
    padding: "20px 20px 60px",
  },
  chip: {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    height: "27px",
    width: "auto",
    minWidth: "98px",
    paddingRight: "6px",
    fontSize: "10px",
    lineHeight: "15px",
    borderRadius: "20px",
    background: "#C9BDF5",
    "&.MuiChip-clickable:hover": {
      backgroundColor: "#C9BDF5",
    },
    "& .MuiChip-deleteIcon": {
      width: "12px",
      height: "12px",
      color: "#000",
    },
  },
  aiChip: {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    height: "27px",
    width: "auto",
    minWidth: "98px",
    paddingRight: "6px",
    fontSize: "10px",
    lineHeight: "15px",
    borderRadius: "20px",
    background: "#92EEDB",
    "&.MuiChip-clickable:hover": {
      backgroundColor: "#92EEDB",
    },
    "& .MuiChip-deleteIcon": {
      width: "12px",
      height: "12px",
      color: "#000",
    },
  },
  clsTag: {
    borderRadius: "4px",
    border: "0.5px solid #E20000",
    fontSize: "8px",
    lineHeight: "12px",
    padding: "2px 16px",
    color: "#E20000",
    textAlign: "center",
  },
  openTag: {
    borderRadius: "4px",
    border: "0.5px solid #139F64",
    fontSize: "8px",
    lineHeight: "12px",
    padding: "2px 16px",
    color: "#139F64",
    textAlign: "center",
  },
})

export default function EditTagsModal({
  open,
  handleClose,
  anchor,
  data,
  url,
  tagData,
  isEditMode = false,
  assetCategory,
  assetCategoryData,
}: EditTagsModalProps) {
  const customStyles = styles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isReadOnly = useAppSelector(selectReadOnlyUser)

  const [isEditTags, setIsEditTags] = React.useState(isEditMode)
  const [isLoadingDownload, setIsLoadingDownload] = React.useState(false)
  const [aiTagData, setAiTagData] = React.useState<AiDataTypes | null>(null)
  const [metaData, setMetadata] =
    React.useState<CreativeAiMetadataTypes | null>(null)
  const [initialFormValues, setInitialFormValues] = React.useState(
    {} as Record<
      string,
      {
        tags: TagObjectType[]
        valueTags: TagObjectType[]
        finalTags: CreativeTagTypes
        category_id: string
      }
    >,
  )
  const [editTagsInAsset, resultData] = useEditTagsInAssetMutation()
  const [trigger, { isLoading: isAssetCategoryLoading }] =
    useLazyGetTagsByAssetCategoryQuery()

  const handleTagClick = (key: string) => {
    const tagData = {
      tagName: key,
      tagValue: data.tag_data[key],
    }
    navigate("/file-explorer", {
      state: {
        tagData: tagData,
      },
    })
  }

  const handleMediaTypeTagClick = (type: string) => {
    navigate("/file-explorer", {
      state: {
        mediaType: type,
      },
    })
  }

  React.useEffect(() => {
    let existingNonDependentTagsInCreative = [] as TagObjectType[]
    const { compulsoryArr, valTags } = getCompulsoryTags(tagData)
    const resVals = getFinalTags(valTags)
    if (!!tagData && !!data.tag_data) {
      // To get the tags that are not dependents of compulsory or compulsory
      const remainingNonDependentTagsArr = tagData.filter(
        (tag) => !valTags.find((t) => t.tagName === tag.tagName),
      )
      // Checking if the remaining tags are also in the creative data
      existingNonDependentTagsInCreative = remainingNonDependentTagsArr.filter(
        (tag) => Object.keys(data.tag_data).some((d) => tag.tagName === d),
      )
    }

    setInitialFormValues({
      ...[data].reduce(
        (a, v) => ({
          ...a,
          [v.creative_id]: {
            tags: [...compulsoryArr, ...existingNonDependentTagsInCreative],
            valueTags: !!resVals ? resVals.finalValTags : [],
            finalTags: !!resVals ? resVals.finalTags : {},
            category_id: assetCategory?.category_id || "",
          },
        }),
        {},
      ),
    })
  }, [data, tagData])

  React.useEffect(() => {
    if (open) {
      setAiTagData(getUpdatedAiTags(data))
      setMetadata(
        !!data.file_metadata
          ? data.file_metadata.data[data.file_data.file_type]
          : null,
      )
    }
  }, [open, data])

  const handleDownload = () => {
    if (!!url && !isLoadingDownload) {
      setIsLoadingDownload(true)
      const fileName = createCustomName(
        data.creative_id,
        data.tag_data,
        data.file_data.s3_filename,
        assetCategory,
      )
      downloadS3AsBlob(url, fileName).finally(() => {
        setIsLoadingDownload(false)
      })
    }
  }

  const getFinalTags = (compulsoryTags: TagObjectType[]) => {
    if (!!data.tag_data && !!tagData) {
      // To check if any new compulsory tags were added in tag config before editing
      const newTags = compulsoryTags
        .filter(
          (tag) =>
            !Object.entries(data.tag_data).some((d) => tag.tagName === d[0]),
        )
        .reduce(
          (a, v) => ({
            ...a,
            [v.tagName]: v.isMultiple ? [] : "",
          }),
          {},
        )
      let finalTags = Object.entries({ ...data.tag_data, ...newTags }).filter(
        (key) => tagData.some((tag) => tag.tagName === key[0]),
      )

      const finalValTags = tagData.filter((tag) =>
        Object.keys({ ...data.tag_data, ...newTags }).some(
          (d) => tag.tagName === d,
        ),
      )
      return {
        finalValTags,
        finalTags: Object.fromEntries(finalTags),
      }
    }
  }

  React.useEffect(() => {
    setIsEditTags(isEditMode)
  }, [open])

  return (
    <Popover
      open={open}
      anchorEl={document.body}
      disablePortal
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left", // Position 320px from the left
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left", // Position 48px from the top
      }}
      sx={{
        "& > .MuiPaper-root": {
          width: { xs: "100%" },
          top: "48px",
        },
        top: "72px",
        left: "320px",
      }}
    >
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialFormValues}
        validate={(values) => {
          let errors: any = {}

          const creative_id = data.creative_id
          const { valueTags, finalTags } = values[creative_id]

          valueTags.forEach((tag) => {
            const tagValue = finalTags[tag.tagName]
            const isTagValueValidType =
              (tag.type === "string" && typeof tagValue === "string") ||
              (tag.type === "number" && typeof tagValue === "number")

            // if (!isTagValueValidType) {
            //   errors = {
            //     ...errors,
            //     [`${creative_id}.finalTags.${tag.tagName}`]: `Value should be a ${tag.type}`,
            //   }
            // }

            if (
              (tagValue === null ||
                tagValue === undefined ||
                (typeof tagValue === "string" && tagValue === "") ||
                (Array.isArray(tagValue) && tagValue.length === 0)) &&
              tag.compulsory
            ) {
              errors = {
                ...errors,
                [`${creative_id}.finalTags.${tag.tagName}`]: "*Required",
              }
            } else if (
              (tagValue === null ||
                tagValue === undefined ||
                (typeof tagValue === "string" && tagValue === "") ||
                (Array.isArray(tagValue) && tagValue.length === 0)) &&
              !tag.compulsory
            ) {
              errors = {
                ...errors,
                [`${creative_id}.finalTags.${tag.tagName}`]: "*Required",
              }
            }
          })

          if (!values[creative_id].category_id.length) {
            errors = {
              ...errors,
              [`${creative_id}.category_id`]:
                "*Please select an asset category",
            }
          }

          if (Object.keys(errors).length) {
            return errors
          }
        }}
        onSubmit={async (values) => {
          const res = {
            ...values[data.creative_id].finalTags,
            [values[data.creative_id].category_id]: true,
          }

          try {
            const result = await editTagsInAsset({
              id: data.creative_id,
              tags: res,
            }).unwrap()
            handleClose()
            setIsEditTags(false)
            dispatch(
              enqueueSnackbar({
                message: `Successfully edited Tags in assets`,
                options: {
                  key: "editing_tags_in_asset_success",
                  variant: "success",
                },
              }),
            )
          } catch (error) {
            handleClose()
            setIsEditTags(false)
            dispatch(
              enqueueSnackbar({
                message: `Editing Tags in assets failed`,
                options: {
                  key: "editing_tags_in_asset_failed",
                  variant: "error",
                },
              }),
            )
          }
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          isSubmitting,
          touched,
          handleBlur,
        }) => {
          return (
            <Form>
              {!!data.file_data ? (
                <Box sx={customStyles.container}>
                  <Grid
                    container
                    gap="34px"
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Grid
                      container
                      item
                      direction="column"
                      gap="9px"
                      alignItems="flex-start"
                    >
                      <KeyboardBackspace
                        fontSize="medium"
                        onClick={() => {
                          handleClose()
                          setIsEditTags(false)
                        }}
                        sx={{ cursor: "pointer" }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      gap="45px"
                      direction="column"
                      sx={{
                        "& > img": {
                          height: { xs: "240px", sm: "406px" },
                          objectFit: "cover",
                          borderRadius: "6px",
                        },
                        "& > video": {
                          height: { xs: "240px", sm: "406px" },
                          objectFit: "cover",
                          borderRadius: "6px",
                        },
                      }}
                    >
                      <Box position="relative" width="100%">
                        {data.file_data.file_type.indexOf("image") > -1 ? (
                          <img
                            src={!!url ? url : ""}
                            alt={data.file_data.filename}
                            width="100%"
                            style={{ objectFit: "contain", maxHeight: "406px" }}
                          ></img>
                        ) : (
                          <video
                            src={!!url ? url : ""}
                            playsInline
                            controls
                            loop
                            width="100%"
                            style={{ objectFit: "contain", maxHeight: "406px" }}
                            controlsList="nodownload"
                            disablePictureInPicture
                          ></video>
                        )}
                        <Box
                          position="absolute"
                          right="8px"
                          bottom="28px"
                          sx={{ cursor: "pointer" }}
                          onClick={handleDownload}
                        >
                          {isLoadingDownload ? (
                            <CircularProgress size="32px" />
                          ) : (
                            <img
                              src={downloadIcon}
                              alt="download icon"
                              width="32px"
                            ></img>
                          )}
                        </Box>
                      </Box>
                      <Grid
                        container
                        item
                        rowGap={3}
                        sx={{
                          p: { xs: 0, sm: "0px 20px" },
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                      >
                        <Grid
                          container
                          item
                          gap="12px"
                          flexWrap="nowrap"
                          sx={{ flexBasis: { sm: "50%" } }}
                        >
                          <Typography
                            fontWeight={500}
                            variant="caption"
                            whiteSpace="nowrap"
                            minWidth="90px"
                          >
                            Synology Name
                          </Typography>
                          <Typography fontWeight={500} variant="caption">
                            :
                          </Typography>
                          <Typography fontWeight={500} variant="caption">
                            {data.file_data.filename}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          gap="12px"
                          flexWrap="nowrap"
                          sx={{ flexBasis: { sm: "50%" } }}
                        >
                          <Typography
                            fontWeight={500}
                            variant="caption"
                            whiteSpace="nowrap"
                            minWidth="90px"
                          >
                            S3 Location
                          </Typography>
                          <Typography fontWeight={500} variant="caption">
                            :
                          </Typography>
                          <Typography
                            fontWeight={500}
                            variant="caption"
                            sx={{ wordBreak: "break-all" }}
                          >
                            {!!url ? url?.split("?")[0] : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          gap="12px"
                          flexWrap="nowrap"
                          sx={{ flexBasis: { sm: "50%" } }}
                        >
                          <Typography
                            fontWeight={500}
                            variant="caption"
                            whiteSpace="nowrap"
                            minWidth="90px"
                          >
                            Synology Path
                          </Typography>
                          <Typography fontWeight={500} variant="caption">
                            :
                          </Typography>
                          <Typography
                            fontWeight={500}
                            variant="caption"
                            sx={{ wordBreak: "break-all" }}
                          >
                            {data.file_data.uploader_file_path}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          gap="12px"
                          flexWrap="nowrap"
                          sx={{ flexBasis: { sm: "50%" } }}
                        >
                          <Typography
                            fontWeight={500}
                            variant="caption"
                            whiteSpace="nowrap"
                            minWidth="90px"
                          >
                            File Type
                          </Typography>
                          <Typography fontWeight={500} variant="caption">
                            :
                          </Typography>
                          <Typography fontWeight={500} variant="caption">
                            {data.file_data.file_type.indexOf("mp4") > -1
                              ? "video"
                              : "image"}
                          </Typography>
                        </Grid>
                        {!!metaData ? (
                          <React.Fragment>
                            <Grid
                              container
                              item
                              gap="12px"
                              flexWrap="nowrap"
                              sx={{ flexBasis: { sm: "50%" } }}
                            >
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                whiteSpace="nowrap"
                                minWidth="90px"
                              >
                                Duration
                              </Typography>
                              <Typography fontWeight={500} variant="caption">
                                :
                              </Typography>
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                sx={{ wordBreak: "break-all" }}
                              >
                                {metaData.duration}
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              gap="12px"
                              flexWrap="nowrap"
                              sx={{ flexBasis: { sm: "50%" } }}
                            >
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                whiteSpace="nowrap"
                                minWidth="90px"
                              >
                                Dimensions
                              </Typography>
                              <Typography fontWeight={500} variant="caption">
                                :
                              </Typography>
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                sx={{ wordBreak: "break-all" }}
                              >
                                {metaData.dimensions.join(" ")}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              gap="12px"
                              flexWrap="nowrap"
                              sx={{ flexBasis: { sm: "50%" } }}
                            >
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                whiteSpace="nowrap"
                                minWidth="90px"
                              >
                                File Size
                              </Typography>
                              <Typography fontWeight={500} variant="caption">
                                :
                              </Typography>
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                sx={{ wordBreak: "break-all" }}
                              >
                                {`${(
                                  metaData.file_size /
                                  (1024 * 1024)
                                ).toFixed(2)} MB`}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              gap="12px"
                              flexWrap="nowrap"
                              sx={{ flexBasis: { sm: "50%" } }}
                            >
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                whiteSpace="nowrap"
                                minWidth="90px"
                              >
                                FPS
                              </Typography>
                              <Typography fontWeight={500} variant="caption">
                                :
                              </Typography>
                              <Typography
                                fontWeight={500}
                                variant="caption"
                                sx={{ wordBreak: "break-all" }}
                              >
                                {metaData.fps}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          borderRadius: "6px",
                          background: "#F5F5F5",
                          p: { xs: "16px", sm: "28px 32px 36px 32px" },
                        }}
                      >
                        {isEditTags && !!tagData ? (
                          <Grid
                            container
                            item
                            direction="column"
                            rowGap="36px"
                            p="24px 14px"
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "6px",
                            }}
                          >
                            <Grid
                              container
                              gap={2}
                              flexDirection="column"
                              flexWrap="nowrap"
                              sx={{
                                background: "#fff",
                                borderRadius: "4px",
                                p: "12px",
                              }}
                            >
                              <Typography variant="caption" fontWeight={500}>
                                Asset Category
                              </Typography>
                              <Grid container direction="column" gap={1}>
                                <Select
                                  displayEmpty
                                  sx={{
                                    width: "300px",
                                    height: "40px",
                                    borderRadius: "6px",
                                    border: "1px solid #ddd",
                                    "& fieldset": {
                                      border: "none",
                                    },
                                  }}
                                  onChange={(e) => {
                                    trigger({
                                      categoryId: e.target.value,
                                      validationVersion: "latest",
                                    }).then((res) => {
                                      if (!!res.data) {
                                        const { compulsoryArr, valTags } =
                                          getCompulsoryTags(res.data)
                                        const finalTags = {
                                          ...valTags.reduce(
                                            (a, v) => ({
                                              ...a,
                                              [v.tagName]: !!initialFormValues[
                                                data.creative_id
                                              ].finalTags[v.tagName]
                                                ? initialFormValues[
                                                    data.creative_id
                                                  ].finalTags[v.tagName]
                                                : "",
                                            }),
                                            {},
                                          ),
                                        }
                                        setFieldValue(data.creative_id, {
                                          finalTags: finalTags,
                                          tags: compulsoryArr,
                                          valueTags: valTags,
                                          category_id: e.target.value,
                                        })
                                      }
                                    })
                                  }}
                                  value={values[data.creative_id].category_id}
                                >
                                  <MenuItem value="">
                                    {isAssetCategoryLoading
                                      ? "Loading..."
                                      : "Please select asset category"}
                                  </MenuItem>
                                  {assetCategoryData.map((category) => (
                                    <MenuItem
                                      key={category.category_id}
                                      value={category.category_id}
                                    >
                                      {category.display_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {!!errors[`${data.creative_id}.category_id`] ? (
                                  <Typography fontSize="10px" color="red">
                                    {
                                      errors[
                                        `${data.creative_id}.category_id`
                                      ] as string
                                    }
                                  </Typography>
                                ) : null}
                              </Grid>
                            </Grid>
                            {values[data.creative_id]?.tags.filter(
                              (d) => d.compulsory,
                            ).length ? (
                              <Grid
                                container
                                gap={2}
                                flexDirection="column"
                                flexWrap="nowrap"
                                sx={{
                                  background: "#fff",
                                  borderRadius: "4px",
                                  p: "12px",
                                }}
                              >
                                <Typography variant="caption" fontWeight={500}>
                                  Compulsory Tags
                                </Typography>
                                <Grid container gap={2}>
                                  {values[data.creative_id].tags
                                    .filter((d) => d.compulsory)
                                    .map(
                                      (tag: TagObjectType, index: number) => (
                                        <TagFieldComponent
                                          key={tag.tagName}
                                          creativeId={data.creative_id}
                                          tag={tag}
                                          errors={errors}
                                          touched={touched}
                                          handleBlur={handleBlur}
                                          values={values}
                                          handleSelectChange={(e, tag) => {
                                            setFieldValue(
                                              `${data.creative_id}.finalTags["${tag.tagName}"]`,
                                              e.target.value,
                                            )
                                          }}
                                          handleTextFieldChange={(e, tag) => {
                                            setFieldValue(
                                              `${data.creative_id}.finalTags["${tag.tagName}"]`,
                                              tag.type === "string"
                                                ? e.target.value
                                                : +e.target.value,
                                            )
                                          }}
                                        />
                                      ),
                                    )}
                                </Grid>
                              </Grid>
                            ) : null}
                            <Grid
                              container
                              gap={2}
                              flexDirection="column"
                              flexWrap="nowrap"
                              sx={{
                                background: "#fff",
                                borderRadius: "4px",
                                p: "12px",
                              }}
                            >
                              {!!tagData?.length ? (
                                <Grid
                                  container
                                  item
                                  direction="column"
                                  gap="20px"
                                  sx={{ mt: { xs: "0px" } }}
                                >
                                  <Typography
                                    variant="caption"
                                    fontWeight={500}
                                  >
                                    Add a Tag Type
                                  </Typography>
                                  <TagsSelectorComponent
                                    tagData={tagData}
                                    value={values[data.creative_id]?.valueTags}
                                    handleChange={(e, value) => {
                                      const autoCompleteValues = [
                                        ...value,
                                      ] as TagObjectType[]

                                      setFieldValue(`${data.creative_id}`, {
                                        tags: autoCompleteValues,
                                        valueTags: autoCompleteValues,
                                        finalTags: {
                                          ...autoCompleteValues.reduce(
                                            (a, v) => ({
                                              ...a,
                                              [v.tagName]: !!values[
                                                data.creative_id
                                              ].finalTags
                                                ? values[data.creative_id]
                                                    .finalTags[v.tagName] || ""
                                                : "",
                                            }),
                                            {},
                                          ),
                                        },
                                        category_id:
                                          values[data.creative_id].category_id,
                                      })
                                    }}
                                    fullWidth
                                    disablePortal={false}
                                  />
                                </Grid>
                              ) : null}
                              {values[data.creative_id]?.tags.filter(
                                (d) => !d.compulsory,
                              ).length ? (
                                <Grid container gap={3}>
                                  {values[data.creative_id].tags
                                    .filter((d) => !d.compulsory)
                                    .map(
                                      (tag: TagObjectType, index: number) => (
                                        <TagFieldComponent
                                          key={tag.tagName}
                                          creativeId={data.creative_id}
                                          tag={tag}
                                          errors={errors}
                                          touched={touched}
                                          handleBlur={handleBlur}
                                          values={values}
                                          handleSelectChange={(e, tag) => {
                                            setFieldValue(
                                              `${data.creative_id}.finalTags["${tag.tagName}"]`,
                                              e.target.value,
                                            )
                                          }}
                                          handleTextFieldChange={(e, tag) => {
                                            setFieldValue(
                                              `${data.creative_id}.finalTags["${tag.tagName}"]`,
                                              tag.type === "string"
                                                ? e.target.value
                                                : +e.target.value,
                                            )
                                          }}
                                        />
                                      ),
                                    )}
                                </Grid>
                              ) : null}
                            </Grid>
                            <Grid container justifyContent="flex-end" gap={2}>
                              <Button
                                variant="contained"
                                onClick={() => setIsEditTags(false)}
                                sx={{
                                  width: "112px",
                                  alignSelf: {
                                    xs: "flex-start",
                                    md: "flex-end",
                                  },
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                  width: "112px",
                                  alignSelf: {
                                    xs: "flex-start",
                                    md: "flex-end",
                                  },
                                }}
                                disabled={!tagData || isSubmitting}
                              >
                                {isSubmitting ? (
                                  <CircularProgress
                                    size={24}
                                    sx={{ color: "#000" }}
                                  />
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container item direction="column">
                            {!isReadOnly ? (
                              <Grid item alignSelf="flex-end">
                                <IconButton
                                  sx={{ color: "#000" }}
                                  onClick={() => setIsEditTags(true)}
                                >
                                  <EditOutlined
                                    sx={{ width: "17px", height: "17px" }}
                                  />
                                </IconButton>
                              </Grid>
                            ) : null}
                            <Grid container item gap="20px">
                              <Grid
                                container
                                item
                                direction="column"
                                gap="12px"
                                alignItems="flex-start"
                              >
                                <Typography fontWeight={500} variant="caption">
                                  Media Type
                                </Typography>
                                <Chip
                                  label={
                                    data.file_data.file_type.indexOf("video") >
                                    -1
                                      ? "video"
                                      : "image"
                                  }
                                  variant="outlined"
                                  onClick={() =>
                                    handleMediaTypeTagClick(
                                      data.file_data.file_type.indexOf(
                                        "video",
                                      ) > -1
                                        ? "video"
                                        : "image",
                                    )
                                  }
                                  sx={customStyles.chip}
                                />
                              </Grid>
                              {!!assetCategory ? (
                                <Grid
                                  container
                                  item
                                  direction="column"
                                  gap="12px"
                                  alignItems="flex-start"
                                >
                                  <Typography
                                    fontWeight={500}
                                    variant="caption"
                                  >
                                    Asset Category
                                  </Typography>
                                  <Chip
                                    label={assetCategory.display_name}
                                    variant="outlined"
                                    sx={customStyles.chip}
                                  />
                                </Grid>
                              ) : null}
                              {!!Object.keys(data.tag_data).length ? (
                                <Grid
                                  container
                                  item
                                  direction="column"
                                  gap="12px"
                                  alignItems="flex-start"
                                >
                                  <Typography
                                    fontWeight={500}
                                    variant="caption"
                                  >
                                    Tags
                                  </Typography>
                                  <Grid container item gap="18px">
                                    {Object.keys(data.tag_data).map((key) => {
                                      if (
                                        !!assetCategory &&
                                        key === assetCategory.category_id
                                      ) {
                                        return null
                                      } else {
                                        return (
                                          <Chip
                                            key={key}
                                            label={data.tag_data[key]}
                                            variant="outlined"
                                            onClick={() => handleTagClick(key)}
                                            sx={customStyles.chip}
                                          />
                                        )
                                      }
                                    })}
                                  </Grid>
                                </Grid>
                              ) : null}
                              {!!aiTagData ? (
                                <Grid
                                  container
                                  item
                                  direction="column"
                                  gap="12px"
                                  alignItems="flex-start"
                                >
                                  <Typography
                                    fontWeight={500}
                                    variant="caption"
                                  >
                                    Ai Tags
                                  </Typography>
                                  <Grid container item gap="18px">
                                    {aiTagData.ai_tag_data.labels_names.map(
                                      (key) => (
                                        <Chip
                                          key={key}
                                          label={key}
                                          variant="outlined"
                                          sx={customStyles.chip}
                                        />
                                      ),
                                    )}
                                  </Grid>
                                </Grid>
                              ) : null}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {/* <Grid
                        container
                        item
                        direction="column"
                        gap="28px"
                        sx={{
                          p: { xs: "16px", md: "18px 50px 50px 50px" },
                          borderRadius: "6px",
                          border: "0.2px solid #000",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Typography variant="body2" fontWeight={700}>
                          Timestamp
                        </Typography>
                        <Grid container item gap="18px">
                          <Grid item container gap="18px" flexWrap="nowrap">
                            <Select
                              color="secondary"
                              displayEmpty
                              sx={customStyles.select}
                              renderValue={() => "Start Time"}
                              IconComponent={KeyboardArrowDown}
                              MenuProps={{ disablePortal: true }}
                            >
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                            </Select>
                            <Select
                              color="secondary"
                              displayEmpty
                              sx={customStyles.select}
                              renderValue={() => "End Time"}
                              IconComponent={KeyboardArrowDown}
                              MenuProps={{ disablePortal: true }}
                            >
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item container flexWrap="nowrap" gap="18px">
                            <TextField
                              placeholder="Comment here"
                              sx={{
                                flexGrow: 1,
                                "& .MuiInputBase-input": {
                                  height: "40px",
                                  padding: {
                                    xs: "0px 0px 0px 16px",
                                    md: "0px 0px 0px 46px",
                                  },
                                },
                              }}
                            ></TextField>
                            <Button variant="contained">Add</Button>
                          </Grid>
                        </Grid>
                        {!isEditTimestamp ? (
                          <Grid
                            container
                            item
                            height="40px"
                            alignItems="center"
                            flexWrap="nowrap"
                            sx={{
                              borderRadius: "6px",
                              border: "0.2px solid #717171",
                              pl: { xs: "8px", md: "28px" },
                              gap: { xs: "8px", md: "18px" },
                            }}
                          >
                            <Grid item xs={4}>
                              <Typography
                                variant="body2"
                                sx={{ color: "#1E1E1E" }}
                                fontWeight={300}
                              >
                                40:00 - 50:00
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={8}
                              justifyContent="space-between"
                              alignItems="center"
                              p="0px 12px"
                            >
                              <Typography variant="body2" fontWeight={500}>
                                Text Here
                              </Typography>
                              <IconButton
                                onClick={() => setIsEditTimestamp(true)}
                              >
                                <EditOutlined
                                  sx={{ width: "17px", height: "17px" }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container item gap="18px">
                            <Select
                              color="secondary"
                              displayEmpty
                              sx={customStyles.select}
                              renderValue={() => "Start Time"}
                              IconComponent={KeyboardArrowDown}
                              MenuProps={{ disablePortal: true }}
                            >
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                            </Select>
                            <Select
                              color="secondary"
                              displayEmpty
                              sx={customStyles.select}
                              renderValue={() => "End Time"}
                              IconComponent={KeyboardArrowDown}
                              MenuProps={{ disablePortal: true }}
                            >
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                              <MenuItem>Media Type</MenuItem>
                            </Select>
                            <TextField
                              placeholder="Comment here"
                              sx={{
                                flexGrow: 1,
                                "& .MuiInputBase-input": {
                                  height: "40px",
                                  padding: "0px 0px 0px 46px",
                                },
                              }}
                            ></TextField>
                            <Grid item container gap="18px">
                              <Button>Save</Button>
                              <Button onClick={() => setIsEditTimestamp(false)}>
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid> */}
                      {/* {isEditTags ? (
                        <Button
                          variant="contained"
                          sx={{
                            alignSelf: { xs: "flex-start", md: "flex-end" },
                            backgroundColor: "#0D99FF",
                            height: "40px",
                            width: "117px",
                            color: "#FFF",
                          }}
                          onClick={() => setIsEditTags(false)}
                        >
                          Save
                        </Button>
                      ) : null} */}
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Form>
          )
        }}
      </Formik>
    </Popover>
  )
}
