import { Button, Grid, Pagination, Stack, Typography } from "@mui/material"
import React from "react"

type PaginationProps = {
  totalPages: number
  currentPage: number
  perPage: number
  totalItems: number
  handleChange: (event: React.ChangeEvent<unknown>, page: number) => void
  isGroupSearch?: boolean
}

export const PaginationComponent = ({
  totalPages,
  currentPage,
  perPage,
  totalItems,
  handleChange,
  isGroupSearch,
}: PaginationProps) => {
  return isGroupSearch ? null : (
    <Stack spacing={2} alignItems="center" direction="column" m={3}>
      <Pagination
        showFirstButton
        showLastButton
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
      />
    </Stack>
  )
}
