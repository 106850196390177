import React from "react"
import { Route, Routes } from "react-router-dom"

import {
  selectIsAuthenticated,
  selectReadOnlyUser,
  selectUserType,
} from "./features/auth/authSlice.js"
import Notifier from "./features/notifier/Notifier"
import { useAppSelector } from "./app/hooks.js"

import ProtectedRoute from "./components/ProtectedRoute.js"
import Theme from "./theme/Theme.js"

import FailedPage from "./pages/HomePage/FailedPage.js"

import "./App.css"

const Login = React.lazy(() => import("./pages/Login/Login.js"))
const Auth = React.lazy(() => import("./pages/Auth/Auth"))
const HomePage = React.lazy(() => import("./pages/HomePage/HomePage.js"))
const Layout = React.lazy(() => import("./components/Layout/Layout.js"))
const ProcessingPage = React.lazy(
  () => import("./pages/HomePage/ProcessingPage.js"),
)
const RecentlyUploadedPage = React.lazy(
  () => import("./pages/HomePage/RecentlyUploadedPage.js"),
)
const FileExplorer = React.lazy(
  () => import("./pages/FileExplorer/FileExplorer.js"),
)

const ErrorPage = React.lazy(() => import("./pages/ErrorPage.js"))

const TagConfig = React.lazy(() => import("./pages/Tags/TagConfig.js"))
const IEC = React.lazy(() => import("./pages/IEC/Generator.js"))

function App() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isReadOnly = useAppSelector(selectReadOnlyUser)
  const userType = useAppSelector(selectUserType)

  return (
    <Theme>
      <Notifier>
        <React.Suspense>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/admin/auth" element={<Auth />} />
            <Route
              path="/"
              element={
                <ProtectedRoute hasPermission={isAuthenticated}>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route index element={<HomePage />} />
              <Route path="processing">
                <Route index element={<ProcessingPage />} />
              </Route>
              <Route path="recently-uploaded">
                <Route index element={<RecentlyUploadedPage />} />
              </Route>
              <Route path="failed">
                <Route index element={<FailedPage />} />
              </Route>
              <Route path="file-explorer/:viewMode?/:activeTab?">
                <Route index element={<FileExplorer />} />
              </Route>
              <Route path="tags-config">
                <Route
                  index
                  element={
                    <ProtectedRoute
                      hasPermission={!isReadOnly && userType === "admin"}
                    >
                      <TagConfig />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="iec">
                <Route
                  index
                  element={
                    <ProtectedRoute hasPermission={!isReadOnly}>
                      <IEC />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </React.Suspense>
      </Notifier>
    </Theme>
  )
}

export default App
