import React from "react"
import createTheme from "@mui/material/styles/createTheme"
import { buttonClasses, CssBaseline, ThemeProvider } from "@mui/material"

type Props = {
  children: React.ReactNode
}
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
  }
}

export default function Theme({ children }: Props) {
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#FFCB05",
      },
      secondary: {
        main: "#242426",
      },
      action: {
        disabled: "#000",
      },
    },
    typography: {
      fontFamily: "Roboto",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.color === "primary" &&
            ownerState.variant === "contained"
              ? {
                  backgroundColor: "#FFCB05",
                  color: "#000",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#242426",
                    color: "#FFCB05",
                  },
                }
              : ownerState.color === "secondary" &&
                ownerState.variant === "contained"
              ? {
                  backgroundColor: "#242426",
                  color: "#FFCB05",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#FFCB05",
                    color: "#000",
                  },
                }
              : null),
          }),
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& .MuiList-root": {
              backgroundColor: "#FFF",
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: "#BABABA",
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "#000",
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1025,
        lg: 1300,
        xl: 1700,
        xxl: 1900,
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
