export const getRange = (input: string): { gte: string; lte: string } => {
  const now = new Date()

  let startDate: Date
  let endDate: Date = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
  )

  if (input.startsWith("custom")) {
    const params = new URLSearchParams(input.split("&").slice(1).join("&"))
    startDate = new Date(params.get("start") || "")
    endDate = new Date(params.get("end") || "")
  } else {
    switch (input) {
      case "today":
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 1,
          0,
          0,
          0,
        )
        break

      case "7-days":
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 7,
          0,
          0,
          0,
        )
        break

      case "14-days":
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 14,
          0,
          0,
          0,
        )
        break

      case "30-days":
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 30,
          0,
          0,
          0,
        )
        break

      case "all":
        startDate = new Date(0)
        break
      //LEGACY PREDEFINED DATE OPTIONS
      // case "today":
      //   startDate = new Date(
      //     now.getFullYear(),
      //     now.getMonth(),
      //     now.getDate(),
      //     0,
      //     0,
      //     0,
      //   )
      //   endDate = new Date(
      //     now.getFullYear(),
      //     now.getMonth(),
      //     now.getDate(),
      //     23,
      //     59,
      //     59,
      //   )
      //   break

      // case "current-week":
      //   const currentDay = now.getDay()
      //   const startOfWeek = new Date(now)
      //   startOfWeek.setDate(
      //     now.getDate() - currentDay + (currentDay === 0 ? -6 : 1),
      //   )

      //   startDate = new Date(
      //     startOfWeek.getFullYear(),
      //     startOfWeek.getMonth(),
      //     startOfWeek.getDate(),
      //     0,
      //     0,
      //     0,
      //   )
      //   endDate = new Date(startDate)
      //   endDate.setDate(startDate.getDate() + 6)
      //   endDate.setHours(23, 59, 59)
      //   break

      // case "current-month":
      //   startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0)
      //   endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
      //   break

      // case "prev-month":
      //   const prevMonth = now.getMonth() - 1
      //   startDate = new Date(now.getFullYear(), prevMonth, 1, 0, 0, 0)
      //   endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59)
      //   break

      // case "all":
      //   startDate = new Date(0)
      //   endDate = new Date(8640000000000000)
      //   break

      default:
        throw new Error("Invalid input")
    }
  }

  return {
    gte: startDate.toISOString(),
    lte: endDate.toISOString(),
  }
}
