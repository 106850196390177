import React from "react"
import {
  Grid,
  Typography,
  Checkbox,
  CircularProgress,
  Box,
} from "@mui/material"

import { useDeleteCreativeMutation } from "../app/services/tags.js"
import { useAppDispatch } from "../app/hooks.js"
import { enqueueSnackbar } from "../features/notifier/notifierSlice.js"
import { CreativeObjectType } from "../model/creative.js"

import { MediaCardItem } from "./MediaCardItem.js"
import { ConfirmModal } from "./ConfirmModal.js"

import tagIcon from "../assets/tag-icon.png"

export type MediaCardProps = CreativeObjectType & {
  isSelected?: boolean
  onSelect?: (e: React.ChangeEvent<HTMLInputElement>) => void
  urlObj?: { asset: string; thumbnail: string }
  onActionClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  disableCheckbox?: boolean
  handleEdit?: () => void
  className?: string
  selectedCards: CreativeObjectType[]
}

const styles = (hover: boolean, selectedCards: CreativeObjectType[]) => ({
  container: {
    boxShadow: "4px 4px 10px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    position: "relative",
    width: "inherit",
    cursor: "pointer",
    transition: "transform 225ms ease-in-out",
    transform: hover ? "scale(1.05)" : "",
    gridColumn: "auto / span 4",
    aspectRatio: "16/15",
  },
  checkbox: {
    display: hover || selectedCards.length ? "block" : "none",
    position: "absolute",
    right: "0px",
    color: "#FFCB05",
    "&.Mui-checked": {
      color: "#FFCB05",
    },
  },
  progress: {
    color: "#FFF",
    animationDuration: "3s",
    "& .MuiCircularProgress-circle": {
      animationDuration: "3s",
    },
  },
  progressWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate3d(-50%, -50%, 0)",
    height: "4rem",
  },
  nameTypography: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  mediaWrapper: {
    background: "#C9C9C9",
    width: "100%",
    height: "70%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
})

export const MediaCard = ({
  creative_id,
  file_data: { upload_status, filename, file_type },
  created_at,
  tag_data,
  urlObj,
  isSelected,
  onSelect,
  onActionClick,
  disableCheckbox = false,
  handleEdit,
  className,
  selectedCards,
}: MediaCardProps) => {
  const [hover, setHover] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const checkboxRef = React.useRef<HTMLInputElement>(null)

  const [deleteCreative, result] = useDeleteCreativeMutation()
  const dispatch = useAppDispatch()

  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteCreative({
        creativeId: creative_id,
        status: upload_status,
      }).unwrap()
      setIsModalOpen(false)
      dispatch(
        enqueueSnackbar({
          message: `Creative deleted successfully`,
          options: {
            key: "creative_delete_success",
            variant: "success",
          },
        }),
      )
    } catch (error) {
      setIsModalOpen(false)
      dispatch(
        enqueueSnackbar({
          message: `Creative deletion failed`,
          options: {
            key: "creative_delete_failed",
            variant: "error",
          },
        }),
      )
    }
  }
  const customStyles = styles(hover, selectedCards)
  return (
    <React.Fragment>
      <Grid
        className={className}
        onClick={onActionClick}
        container
        sx={customStyles.container}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
          if (!disableCheckbox) {
            if (!isSelected && hover) {
              setHover(false)
            } else if (!isSelected && !hover) {
              setHover(false)
            }
          } else {
            setHover(false)
          }
        }}
      >
        <Box sx={customStyles.mediaWrapper}>
          <MediaCardItem
            fileType={file_type}
            uploadStatus={upload_status}
            alt={filename}
            src={
              !!urlObj && urlObj.thumbnail.length
                ? urlObj.thumbnail
                : urlObj?.asset
            }
            isThumbnail={!!urlObj && urlObj.thumbnail.length ? true : false}
          />
          {upload_status === "uploading" ? (
            <Box sx={customStyles.progressWrapper}>
              <CircularProgress sx={customStyles.progress} size="4rem" />
            </Box>
          ) : null}
        </Box>
        <Grid
          container
          direction="column"
          p={1}
          flexGrow={1}
          justifyContent="space-between"
          sx={{
            backgroundColor: hover ? "#B7DDF7" : "",
            borderRadius: "0 0 8px 8px",
            minHeight: "30%",
          }}
        >
          <Typography variant="body1" sx={customStyles.nameTypography}>
            {filename}
          </Typography>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container gap={1} alignItems="center" width="auto">
              <Typography variant="caption" sx={{ alignSelf: "flex-start" }}>
                {new Date(created_at).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>
              {!!Object.keys(tag_data).length ? (
                <img src={tagIcon} width="12px" height="12px" alt="" />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {upload_status === "failed" ||
        (!!Object.keys(tag_data).length && upload_status === "uploading") ||
        disableCheckbox ? null : (
          <Checkbox
            sx={customStyles.checkbox}
            inputRef={checkboxRef}
            onClick={(e) => e.stopPropagation()}
            onChange={onSelect}
            checked={isSelected}
          />
        )}
      </Grid>
      <ConfirmModal
        open={isModalOpen}
        confirmText="Do you really want to delete the asset, this action cannot be reversed"
        handleClose={() => setIsModalOpen(false)}
        isLoading={result.isLoading}
        handleSubmit={handleDeleteConfirm}
      />
    </React.Fragment>
  )
}
