import React from "react"
import { useNavigate } from "react-router-dom"
import {
  Drawer,
  IconButton,
  styled,
  Grid,
  Typography,
  Chip,
  Button,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material"
import {
  ArrowRightAlt,
  Close,
  EditOutlined,
  DeleteOutlined,
  Loop,
  ShareOutlined,
} from "@mui/icons-material"

import EditTagsModal from "./EditTagsModal.js"
import { ConfirmModal } from "./ConfirmModal.js"

import {
  AiDataTypes,
  CreativeAiMetadataTypes,
  CreativeObjectType,
  TagObjectType,
} from "@/model/creative"
import {
  useDeleteCreativeMutation,
  useGetAllAssetCategoriesQuery,
} from "@/app/services/tags"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { enqueueSnackbar } from "../features/notifier/notifierSlice.js"
import { selectReadOnlyUser } from "@/features/auth/authSlice.js"

import { createCustomName, downloadS3AsBlob } from "../util/downloadFromS3.js"
import { getUpdatedAiTags } from "@/util/getUpdatedAiTags.js"

import downloadIconSvg from "@/assets/download-icon.svg"
import emptyImage from "@/assets/processing-image.jpeg"

const drawerWidth = 415

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
}))

const styles = () => ({
  drawer: {
    flexShrink: 0,
    height: "fit-content",
    "& .MuiDrawer-paper": {
      position: "static",
      maxWidth: { xs: "100%", sm: drawerWidth },
      borderRadius: "14px",
      background: "#FFF",
      overflowY: "scroll",
      visibility: "visible !important",
    },
  },
  chip: {
    justifyContent: "space-between",
    height: "27px",
    width: "auto",
    fontSize: "10px",
    lineHeight: "15px",
    borderRadius: "20px",
    background: "#C9BDF5",
    "&.MuiChip-clickable:hover": {
      backgroundColor: "#C9BDF5",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    "& .MuiChip-deleteIcon": {
      width: "12px",
      height: "12px",
      color: "#000",
    },
  },
  viewDetailsBtn: {
    backgroundColor: "#FFCB05",
    color: "#000",
    "&:hover": {
      backgroundColor: "#000",
      color: "#FFCB05",
    },
  },
})

type ExplorerDrawerProps = {
  isOpen: boolean
  data: CreativeObjectType
  handleClose: () => void
  handleClick?: () => void
  url?: string
  assetTagData?: TagObjectType[]
}

export default function ExplorerDrawer({
  isOpen,
  handleClose,
  handleClick,
  data,
  url,
  assetTagData,
}: ExplorerDrawerProps) {
  const customStyles = styles()
  const dispatch = useAppDispatch()
  const ref = React.useRef(null)
  const navigate = useNavigate()

  const isReadOnly = useAppSelector(selectReadOnlyUser)

  const [tooltipTitle, setTooltipTitle] =
    React.useState<string>("Copy Share URL")
  const [isLoadingDownload, setIsLoadingDownload] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false)
  const [aiTagData, setAiTagData] = React.useState<AiDataTypes | null>(null)
  const [metaData, setMetadata] =
    React.useState<CreativeAiMetadataTypes | null>(null)
  const [assetCategory, setAssetCategory] = React.useState<{
    display_name: string
    category_id: string
  }>()

  const [deleteCreative, { isLoading: deleteIsLoading }] =
    useDeleteCreativeMutation()
  const { data: assetCategoryData } = useGetAllAssetCategoriesQuery()

  React.useEffect(() => {
    if (isOpen) {
      setAiTagData(getUpdatedAiTags(data))
      setMetadata(
        !!data.file_metadata
          ? data.file_metadata.data[data.file_data.file_type]
          : null,
      )
    }
  }, [isOpen, data])

  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteCreative({
        creativeId: data.creative_id,
        status: data.file_data.upload_status,
      }).unwrap()
      setIsConfirmModalOpen(false)
      handleClose()
      dispatch(
        enqueueSnackbar({
          message: `Creative deleted successfully`,
          options: {
            key: "creative_delete_success",
            variant: "success",
          },
        }),
      )
    } catch (error) {
      setIsConfirmModalOpen(false)
      handleClose()
      dispatch(
        enqueueSnackbar({
          message: `Creative deletion failed`,
          options: {
            key: "creative_delete_failed",
            variant: "error",
          },
        }),
      )
    }
  }

  const handleDownload = () => {
    if (!!url && !isLoadingDownload) {
      setIsLoadingDownload(true)
      const fileName = createCustomName(
        data.creative_id,
        data.tag_data,
        data.file_data.s3_filename,
        assetCategory,
      )
      downloadS3AsBlob(url, fileName).finally(() => {
        setIsLoadingDownload(false)
      })
    }
  }

  const handleTagClick = (key: string, isAiKey = false) => {
    navigate(
      `/file-explorer${
        !localStorage.getItem("isListView") ||
        localStorage.getItem("isListView") === "true"
          ? "/list"
          : "/grid"
      }${
        !localStorage.getItem("tabName") ||
        localStorage.getItem("tabName") === "all"
          ? ""
          : "/" + localStorage.getItem("tabName")
      }?${isAiKey ? "ai_tag=" + key : `tag_${key}=` + data.tag_data[key]}`,
    )
  }

  const handleIECGeneration = (name: string, type: string) => {
    if (!!url) {
      const iecData = {
        name,
        type,
        url: url,
      }
      navigate("/iec", { state: iecData })
      handleClose()
    }
  }

  const getAssetCategory = () => {
    if (!!assetCategoryData) {
      setAssetCategory(
        assetCategoryData.find((category) =>
          Object.keys(data.tag_data).some((d) => d === category.category_id),
        ),
      )
    }
  }

  const copyToClipboard = (): void => {
    const textToCopy: string = `${window.location.origin}${location.pathname}?asset_id=${data.creative_id}`
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTooltipTitle("Copied!")
        setTimeout(() => {
          setTooltipTitle("Copy Share URL")
        }, 2000) // Hide tooltip after 2 seconds
      })
      .catch((err: Error) => {
        console.error("Failed to copy: ", err)
      })
  }

  React.useEffect(() => {
    if (!!Object.keys(data).length) {
      getAssetCategory()
    }
  }, [data])

  return (
    <Drawer
      sx={customStyles.drawer}
      variant="persistent"
      anchor="right"
      open={isOpen && !!data.file_data}
      ref={ref}
      onClose={handleClose}
      transitionDuration={200}
    >
      {isOpen ? (
        <Box>
          <DrawerHeader
            sx={{ mt: 3, justifyContent: "space-between", px: "20px" }}
          >
            {!!data.tag_data &&
            Object.keys(data.tag_data).length &&
            data.file_data.upload_status === "success" ? (
              <Grid container gap="10px">
                {!isReadOnly ? (
                  <IconButton
                    sx={{ fontSize: "0.75em", p: 0 }}
                    onClick={handleClick}
                  >
                    Edit
                    <EditOutlined
                      sx={{ width: "22px", height: "22px", paddingLeft: "6px" }}
                    />
                  </IconButton>
                ) : null}
                <Tooltip
                  title={tooltipTitle}
                  disableFocusListener
                  disableTouchListener
                >
                  <IconButton
                    sx={{ fontSize: "0.75em", p: 0, color: "#0D99FF" }}
                    onClick={copyToClipboard}
                  >
                    Share
                    <ShareOutlined
                      sx={{ width: "22px", height: "22px", paddingLeft: "6px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
            <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
              <Close />
            </IconButton>
          </DrawerHeader>
          {!!data.file_data ? (
            <Grid container p="20px" gap={5}>
              <Box position="relative" width="100%">
                {data.file_data.file_type.split("/")[0] === "image" ? (
                  <img
                    src={!!url ? url : emptyImage}
                    alt={data.creative_id}
                    width="100%"
                    height="268px"
                    style={{ objectFit: "cover", borderRadius: "9px" }}
                  ></img>
                ) : (
                  <video
                    src={!!url ? url : ""}
                    playsInline
                    controls
                    loop
                    width="100%"
                    height="268px"
                    style={{ objectFit: "contain", borderRadius: "9px" }}
                    controlsList="nodownload"
                    disablePictureInPicture
                  ></video>
                )}
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ mt: 1, p: 0 }}
                >
                  {data.file_data.upload_status === "success" ? (
                    isLoadingDownload ? (
                      <CircularProgress size="20px" />
                    ) : (
                      <IconButton
                        sx={{ fontSize: "0.75em", color: "#0D99FF", p: 0 }}
                        onClick={handleDownload}
                      >
                        <img
                          src={downloadIconSvg}
                          alt="download icon"
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "6px",
                          }}
                        ></img>
                        Download
                      </IconButton>
                    )
                  ) : null}
                  {!isReadOnly ? (
                    <>
                      <IconButton
                        sx={{ fontSize: "0.75em", color: "#0D99FF", p: 0 }}
                        onClick={() =>
                          handleIECGeneration(
                            data.file_data.filename,
                            data.file_data.file_type,
                          )
                        }
                      >
                        <Loop
                          sx={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "6px",
                          }}
                        />
                        Generate IEC
                      </IconButton>
                      <IconButton
                        sx={{ fontSize: "0.75em", color: "red", p: 0 }}
                        onClick={() => {
                          setIsConfirmModalOpen(true)
                        }}
                      >
                        <DeleteOutlined
                          sx={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "6px",
                            fill: "red",
                          }}
                        />
                        Delete
                      </IconButton>
                    </>
                  ) : null}
                </Grid>
              </Box>
              <Grid
                container
                item
                sx={{
                  overflowY: "auto",
                  maxHeight: { xs: "200px", sm: "300px" },
                }}
                pb={1}
              >
                <Grid container item direction="column" gap="20px" width="auto">
                  {!!metaData ? (
                    <React.Fragment>
                      <Grid container item gap="12px" flexWrap="nowrap">
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          Duration
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {metaData.duration}
                        </Typography>
                      </Grid>

                      <Grid container item gap="12px" flexWrap="nowrap">
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          Dimensions
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {metaData.dimensions.join(" ")}
                        </Typography>
                      </Grid>
                      <Grid container item gap="12px" flexWrap="nowrap">
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          File Size
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {`${(metaData.file_size / (1024 * 1024)).toFixed(
                            2,
                          )} MB`}
                        </Typography>
                      </Grid>
                      <Grid container item gap="12px" flexWrap="nowrap">
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          FPS
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {metaData.fps}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  {data.file_data.upload_status !== "success" ? (
                    <Grid container item gap="12px" flexWrap="nowrap">
                      <Typography
                        fontWeight={500}
                        variant="caption"
                        whiteSpace="nowrap"
                        minWidth="90px"
                      >
                        Status
                      </Typography>
                      <Typography fontWeight={500} variant="caption">
                        :
                      </Typography>
                      <Typography
                        fontWeight={500}
                        variant="caption"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {data.file_data.upload_status}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container item gap="20px">
                  <Grid
                    container
                    item
                    direction="column"
                    gap="12px"
                    alignItems="flex-start"
                  >
                    <Typography fontWeight={500} variant="caption">
                      Media Type
                    </Typography>
                    <Chip
                      label={
                        data.file_data.file_type.indexOf("video") > -1
                          ? "video"
                          : "image"
                      }
                      variant="outlined"
                      sx={customStyles.chip}
                    />
                  </Grid>
                  {!!assetCategory ? (
                    <Grid
                      container
                      item
                      direction="column"
                      gap="12px"
                      alignItems="flex-start"
                    >
                      <Typography fontWeight={500} variant="caption">
                        Asset Category
                      </Typography>
                      <Chip
                        label={assetCategory.display_name}
                        variant="outlined"
                        sx={customStyles.chip}
                      />
                    </Grid>
                  ) : null}
                  {!!Object.keys(data.tag_data).length ? (
                    <Grid
                      container
                      item
                      direction="column"
                      gap="12px"
                      alignItems="flex-start"
                    >
                      <Typography fontWeight={500} variant="caption">
                        Tags
                      </Typography>
                      <Grid container item gap="18px">
                        {Object.keys(data.tag_data).map((key) => {
                          if (
                            !!assetCategory &&
                            key === assetCategory.category_id
                          ) {
                            return null
                          } else {
                            return (
                              <Chip
                                key={key}
                                label={data.tag_data[key]}
                                variant="outlined"
                                onClick={() => handleTagClick(key)}
                                sx={customStyles.chip}
                              />
                            )
                          }
                        })}
                      </Grid>
                    </Grid>
                  ) : null}
                  {!!aiTagData ? (
                    <Grid
                      container
                      item
                      direction="column"
                      gap="12px"
                      alignItems="flex-start"
                    >
                      <Typography fontWeight={500} variant="caption">
                        Ai Tags
                      </Typography>
                      <Grid container item gap="18px">
                        {aiTagData.ai_tag_data.labels_names.map((key) => (
                          <Chip
                            key={key}
                            label={key}
                            variant="outlined"
                            onClick={() => handleTagClick(key, true)}
                            sx={customStyles.chip}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {data.file_data.upload_status === "success" &&
              !!Object.keys(data.tag_data).length ? (
                <Button
                  fullWidth
                  endIcon={<ArrowRightAlt />}
                  sx={customStyles.viewDetailsBtn}
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  View Details
                </Button>
              ) : null}
            </Grid>
          ) : null}
        </Box>
      ) : null}

      <EditTagsModal
        open={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false)
          handleClose()
        }}
        data={data}
        url={url}
        tagData={assetTagData}
        assetCategory={assetCategory}
        assetCategoryData={assetCategoryData || []}
      />
      <ConfirmModal
        open={isConfirmModalOpen}
        confirmText="Do you really want to delete the asset, this action cannot be reversed"
        handleClose={() => {
          setIsConfirmModalOpen(false)
          handleClose()
        }}
        isLoading={deleteIsLoading}
        handleSubmit={() => handleDeleteConfirm()}
      />
    </Drawer>
  )
}
